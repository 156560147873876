// Libraries
import React, { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { StyleSheet, Dimensions, View, ImageBackground, TouchableWithoutFeedback, findNodeHandle, Text, ScrollView, TouchableOpacity, Image, ActivityIndicator, Modal } from 'react-native';
import { FontAwesome5, AntDesign, Entypo } from '@expo/vector-icons';
import Checkbox from 'expo-checkbox';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';
import ProgressBarAnimated from 'react-native-progress-bar-animated';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

// Functions
import { vergelijk_datums, decideCSS, decideEnable, decideShow, verwerkData, getKeyByValue, isNumeric, stripFirstColon, customConsoleLog, hoverOutsideCard, sendGa, textReplace, get_property } from '../functions/common';
import { get_script, refresh_grafieken } from '../functions/adviezen';
import { clickedOutsideView, sendGaButton } from '../functions/common';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { RESET_PARAM, SET_PARAM } from '../context/actions/commonActions';

// Components
import QuestionBar from '../components/questionBar';
import { Button } from './button';
import { P, H1, H2, H3, H4 } from '../components/text';
import AutocompleteInput from './autoCompleteInput';
import { TextInputDefault } from '../components/textInput';
import DropDown, { CustomDropDown } from '../components/dropDown';
import CustomCheckCard from '../components/customCheckCard';
import DateInput from '../components/dateInput';
import RadioButton from '../components/radioButton';
import GooglePlacesInput from '../components/googlePlacesInput';
import Loader from '../components/loader';
import LeftContainer from '../components/leftContainer';
import RightContainer from '../components/rightContainer';
import IconSetter from './iconSetter';
import Message from '../components/message';

// Variables
let first = true;
let count = 0;
let onlyOnce = true;
let onlyOnceTwo = true;

const vraagBreedte = ( max, vraagtype ) => {

  // bij MC vragen altijd 100% breedte aanhouden
  // de verdeling gaat dan via kolommen
  if ( vraagtype == 'mc_enkel' || vraagtype == 'mc_multi' ) return '100%';

  // als er geen max meegegeven is, dan 33%
  if (!max) return '33%';

  // altijd percentage gebruiken
  return max + '%';

}


export default function VraagContainer(props) {
  Colors = FncColors();
  //Context
  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;
  const debug = commonContext.debug;
  const adviesstap_id = commonContext.adviesstap_id;
  const first_verfijnstap_id = commonContext.first_verfijnstap_id;
  //console.log("adviesstap: " + adviesstap_id);
  //console.log("first_verfijnstap_id: " + first_verfijnstap_id);
  let param = commonContext.param;
  const param_cache = commonContext.param_cache;
  const [verwerkCount, setVerwerkCount] = useState(0);
  const [alertOverlay, setAlertOverlay] = useState(false);

  //Props
  const icon = false;
  const mobile = props.mobile || false;
  const tablet = props.tablet;
  const style = props.style || styles;
  const aom_data = props.aom_data;
  const navigation = props.navigation;
  const buttonContentPrev = props.buttonContentPrev || <H3>Vorige</H3>;
  const buttonContentNext = props.buttonContentNext || <H3>Volgende</H3>;
  const module = aom_data.module;

  //Stap
  const stap = aom_data.stap;

  const progress = stap.progress.progress;
  const vraagtype = stap.vraagtype;
  const stap_id = stap.sta_id;
  const vraagNummer = stap.progress.value;
  const vraagMax = stap.progress.max;
  const maxVraagBreedte = mobile ? '100%' : vraagBreedte( stap.sta_max_vraagbreedte, vraagtype );
  let inputAantal = stap.antwoorden.length;

  const [htmlOverlay, setHtmlOverlay] = useState(false);
  const [html, setHtml] = useState('');


  const home = () => {
    props.navigation.push('Main');
  }

  const isDateValid = (date = "") => {
    if(date && typeof date == 'string'){
      console.log("goeie datum");
      date = date.split("-");
      date = date[2] + "-" + date[1] + "-" + date[0];
      let parsedDate = new Date(date); return parsedDate.toString() !== "Invalid Date"; 
    }

  }

  const isNumber = (number) => !isNaN(parseFloat(number)) && isFinite(number);

  //Input aantal kan bijvoorbeeld rondreis wel 30 zijn, terwijl 1 ding invullen in principe al genoeg is
  if (inputAantal > 10) {
    inputAantal = stap.sta_min;
  }
  //console.log(inputAantal);

  let dependencies = [];
  let icons = [];
  let config = false;

  //Verwerkt de afhankelijkheden
  if (stap.sta_afhankelijkheden) {
    const afhankelijkheden = JSON.parse(stap.sta_afhankelijkheden);
    if ((typeof afhankelijkheden === "object")) {
      if (afhankelijkheden.hasOwnProperty("dependencies")) {
        dependencies = afhankelijkheden.dependencies;
      }

      if (afhankelijkheden.hasOwnProperty("icons")) {
        icons = afhankelijkheden.icons;
      }

      if (afhankelijkheden.hasOwnProperty("config")) {
        config = afhankelijkheden.config;
      }
    }
  }
  //console.log(config);
  //console.log(dependencies);

  //States
  const [inputData, setInputData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [showInput, setShowInput] = useState(true);


  let vervolgstap = stap.sta_vervolgstap_id;

  //Verwerk de chain uit de cache zodat je de inputs kan invullen waar nodig
  let newArrayAnt = [];
  let radioButton = {};
  let checkBox = [];
  let inputDataFilled = 0;
  const chain = param_cache.chain;
  if (chain) {
    const objectIndex = chain.findIndex((obj => obj.stap_id == stap_id));
    if (objectIndex != "-1") {
      if (chain[objectIndex].hasOwnProperty("antwoord_id")) {
        const antwoordIdArray = chain[objectIndex].antwoord_id;
        const type = chain[objectIndex].type;
        if (type == 'mc_multi') {//checkbox
          for (let i = 0; i < antwoordIdArray.length; i++) {
            newArrayAnt[antwoordIdArray[i]] = 1;
            inputDataFilled++;
          }
        }
        else if (type == 'mc_enkel') {//radio button
          radioButton[antwoordIdArray] = 1;
          //console.log(radioButton);
          inputDataFilled++;
        }
        else {
          const antwoordArray = chain[objectIndex].antwoord;
          for (let i = 0; i < antwoordIdArray.length; i++) {
            newArrayAnt[antwoordIdArray[i]] = antwoordArray[i];

            //Check specifiek op '', want de values uit de dependency maken we null
            if (antwoordArray[i] != '') {
              inputDataFilled++;
            }
          }
        }
      }
    }
  }
  //Koppel de type antwoorden samen met de ids zodat je dit later kunt gebruiken

  let typeAntwoordId = {};
  stap.antwoorden.map((data, index) => {
    if (data.variabelenaam) {
      typeAntwoordId[data.variabelenaam] = data.id;
    }

    //Als de antwoorden met radio buttons zijn, dan de vervolgstap halen uit de radiobutton aan de hand van wat eerder is opgegeven
    if (data.input_type == "radio") {
      if (radioButton[data.id]) {
        vervolgstap = data.vervolgstap;
      }
    }

    //Check of er meer dan 1 objecten in staan, als je bijvoorbeeld een activiteit kiest met exclusief = 0, na een activiteit met exclusief = 1, dan degene met exclusief = 1 legen
    //Dit is voor nu de enige oplossing die ik kon bedenken.
    if (Object.keys(newArrayAnt).length > 1) {
      if (data.exclusief == 1) {
        if (newArrayAnt[data.id]) {

          const newAntObject = { [data.id]: 0 }
          setTimeout(function () {
            verwerkData(param_cache, stap_id, vraagtype, newAntObject, commonDispatch, first, null, stap.vraagtype == "open_multi" ? false : true, module.mod_naam);
          }, 100);
        }
      }
    }


    if (newArrayAnt[data.id] == undefined) {
      if (data.default !== undefined) {
        if(isNumber(data.default)){
          newArrayAnt[data.id] = (Math.round(data.default.replace(',', '.') * 10 ** data.dec ) / 10 ** data.dec).toString().replace('.', ',');
        }
        else {
          newArrayAnt[data.id] = data.default;
        }

        //Bij bijvoorbeel dropdown bij rondreis moet je wel alvast de default waarde in de param zetten, anders neemt hij deze niet mee
        if (data.default != "" && data.default != null) {
          // console.log("voeg default toe aan data: " + data.default);
          let newAntObject;
          if(isNumber(data.default)){
            let new_default = (Math.round(data.default.replace(',', '.') * 10 ** data.dec ) / 10 ** data.dec).toString().replace('.', ',');
            newAntObject = { [data.id]: new_default }
          }
          else {
            newAntObject = { [data.id]: data.default }
          }
          if (stap.vraagtype === 'open_multi') {
            if (verwerkCount < 1) { //;stap.antwoorden.length) {
              verwerkData(param_cache, stap_id, vraagtype, newArrayAnt, commonDispatch, first, null, true, module.mod_naam);
              setVerwerkCount(verwerkCount + 1);
            }
          } else {
            verwerkData(param_cache, stap_id, vraagtype, newAntObject, commonDispatch, first, null, true, module.mod_naam);
          }
          inputDataFilled++;
        }
      }
    }
  });

  first = false;

  if (debug) console.log(newArrayAnt);

  //console.log(typeAntwoordId);

  //Verwerk de content die is ingevuld/gekozen en zet dit in de State object
  const setContent = (value, type, antwoordId, vervolgstapId, exclusief = null) => {
    //console.log(exclusief);

    //Set de input data zodat je dit kan checken in de dependencies
    const newInputData = { ...inputData, [type]: value };
    setInputData(newInputData);

    //Vervolgstap id kan uit antwoord komen bij radio buttons
    if (vervolgstapId != null) {
      vervolgstap = vervolgstapId;
      //console.log("vervolgstap: " + vervolgstapId);
    }

    let antwoord = null;
    let role = '';

    //Pas aan de hand van het vraagtype aan wat je in het antwoord object stopt
    switch (vraagtype) {
      case "open_enkel":
        antwoord = value;
        break;
      case "mc_enkel":
        antwoord = value;
        role = 'group';
        break;
      case "mc_multi":
        value = 1 - value;
        antwoord = value;
        role = 'radiogroup';
        break;
      case "open_multi":
        antwoord = value;
        break;
      default:
        setAlertOverlay( {title: 'Foutmelding', message: 'Vraagtype niet gevonden.'} );

        return false;
    }

    let antObject = { [antwoordId]: antwoord };

    //Vewerk de dependencies ook in de antwoorden
    const newAntObject = checkDependency(type, newInputData, antObject);

    //Sla de data op in de asyncStorage en in de commonContext 
    verwerkData(param_cache, stap_id, vraagtype, newAntObject, commonDispatch, true, exclusief, stap.vraagtype == "open_multi" ? false : true, module.mod_naam, vervolgstapId ? vervolgstapId : stap.sta_vervolgstap_id);

    if (debug) console.log("inputData: " + JSON.stringify(newInputData));
  }

  //Loop door de dependencies heen en check of een type overeenkomt met een stap, 
  // als dat zo is dan het verwerken met execDependency.
  const checkDependency = (type, newInputData, antObject) => {
    let values = [];
    let executedDepedency = null;
    if (dependencies) {

      dependencies.map((dependency) => {
        if (type == dependency.als_stap) {
          //console.log(newInputData);
          let value = newInputData[type];
          //console.log(value);
          if (!value) {
            value = '';
          }
          executedDepedency = execDependency(dependency, value, antObject);
        }
      });
    }

    //Voor als het type niet voorkomt
    if (executedDepedency == null) {
      executedDepedency = antObject;
    }

    //console.log(executedDepedency);
    //values.push(executedDepedency);
    return executedDepedency;
  }

  //Vewerk de dependency waarvan het type gelijk is aan de als_stap en pas daarbij de stap 
  // aan die word meegegeven.
  const execDependency = (dependency, val, antObject) => {
    //console.log("dependency: " + JSON.stringify(dependency) + " met val: " + val);

    //console.log(val);
    if (isNumeric(val)) {
      val = parseInt(val);
    }
    //console.log(val);

    //console.log(dependency);
    if (dependency.in.includes(val)) {
      //console.log("Val word gevonden: " + val);
      if (dependency.status == "invisible") {

        if (antObject != null) {
          const antwoordId = typeAntwoordId[dependency.stap];
          if (antwoordId) {//Kan undefined zijn bij rondreis
            const antwoord = dependency.value;
            antObject[antwoordId] = antwoord;
          }
        }
        //Werk status altijd bij
      }

      if (dependency.status == "visible") {
        //Werk status altijd bij
      }

      if (dependency.status == "enabled") {
        //Werk status altijd bij
      }

      if (dependency.status == "disabled") {
        if (antObject != null) {
          const antwoordId = typeAntwoordId[dependency.stap];
          if (antwoordId) {//Kan undefined zijn bij rondreis
            const antwoord = dependency.value;
            antObject[antwoordId] = antwoord;
          }
        }

        //Werk status altijd bij
      }

      //Werk status altijd bij
      if (antObject == null) {

        //Als de stap met een underscore eindigt, dan loopen t/m 10 en daar de status voor aanpassen
        if (dependency.stap.endsWith("_")) {
          //console.log("komt hier bij " + dependency.stap);
          for (let i = 1; i <= 10; i++) {
            const dep_stap = dependency.stap + i;
            //console.log(dep_stap);

            const newStatus = statusData;
            newStatus[dep_stap] = dependency.status;

          }
        }
        else {
          const newStatus = statusData;
          newStatus[dependency.stap] = dependency.status;
        }

        //console.log("werk status bij voor " + dependency.stap);
        /*
        const newStatus = statusData;
        newStatus[dependency.stap] = dependency.status;*/
      }
    }
    else {
      let depStatus = null;
      if (dependency.status == "invisible") {
        if (antObject == null) {
          depStatus = "visible";
          /*
          const newStatus = statusData;
          newStatus[dependency.stap] = "visible";*/
        }
      }

      if (dependency.status == "visible") {
        if (antObject != null) {
          const antwoordId = typeAntwoordId[dependency.stap];
          if (antwoordId) {//Kan undefined zijn bij rondreis
            const antwoord = dependency.value;
            antObject[antwoordId] = antwoord;
          }
        }

        if (antObject == null) {
          depStatus = "invisible";

          /*
          const newStatus = statusData;
          newStatus[dependency.stap] = "invisible";*/
        }
      }

      if (dependency.status == "enabled") {
        if (antObject != null) {
          const antwoordId = typeAntwoordId[dependency.stap];
          if (antwoordId) {//Kan undefined zijn bij rondreis
            const antwoord = dependency.value;
            antObject[antwoordId] = antwoord;
          }
        }

        if (antObject == null) {
          /*
          const newStatus = statusData;
          newStatus[dependency.stap] = "disabled";*/
          depStatus = "disabled";
        }
      }

      if (dependency.status == "disabled") {
        if (antObject == null) {
          /*const newStatus = statusData;
          newStatus[dependency.stap] = "enabled";*/

          depStatus = "enabled";
        }
      }

      //console.log("dep status " + depStatus);
      if (depStatus != null) {
        if (dependency.stap.endsWith("_")) {
          //console.log("komt hier bij " + dependency.stap);
          for (let i = 1; i <= 10; i++) {
            const dep_stap = dependency.stap + i;
            //console.log(dep_stap);

            const newStatus = statusData;
            newStatus[dep_stap] = depStatus;

          }
        }
        else {
          const newStatus = statusData;
          newStatus[dependency.stap] = depStatus;
        }
      }
    }
    //console.log("nieuwe status: " + JSON.stringify(statusData));

    //console.log(antObject);
    return antObject;
  }

  //Status verversen als de container refreshed, voor bijvoorbeeld de eerste keer als de pagina laad
  newArrayAnt.map((data, key) => {
    const type = getKeyByValue(typeAntwoordId, key);

    //console.log(type);
    dependencies.map((dependency) => {
      if (type == dependency.als_stap) {
        //console.log(newInputData);

        let value = newArrayAnt[key];
        //console.log(value);
        if (!value) {
          value = '';
        }
        execDependency(dependency, value, null);
      }
    });
  });

  //Voeg gecontroleerd de volgende chain toe aan de param vanuit de cache, zodat je met prevScreen dit ook weer kan poppen zodat alles klopt
  const nextScreen = () => {
    console.log("vervolgstap: " + vervolgstap);
    //Als vervolgstap 0 is dan gaat er iets fout
    if (vervolgstap == 0 || vervolgstap == -1) {
      setAlertOverlay( {title: 'Foutmelding', message: 'Kies een antwoord'} );
      return false;
    }

    let input_fout = false;
    let input_filled_counter = 0;
    stap.antwoorden.map((data, index) => {
      let max = data.max;
      let min = data.min;
      let value = newArrayAnt[data.id];
      let number_value = '';
      if(value && typeof value == 'string'){
        number_value = value.replace(',', '.');
      }
      if(isNumber(number_value)){
        if(max){
          max = max.replace(',', '.');
          if(Number.parseFloat(number_value) > Number.parseFloat(max)){
            input_fout = true;
            setAlertOverlay( {title: 'Foutmelding', message: "Invoerwaarde mag niet groter zijn dan " + max} );
          }
        }
        if(min){
          min = min.replace(',', '.');
          if(Number.parseFloat(number_value) < Number.parseFloat(min)){
            input_fout = true;
            setAlertOverlay( {title: 'Foutmelding', message: "Invoerwaarde mag niet kleiner zijn dan " + min} );
          }
        }
      } else if(isDateValid(value)){
        //console.log("het is een datum");
        if(max){
          if(!vergelijk_datums(value, max, "max")){
            input_fout = true;
          }
        }
        if(min){
          if(!vergelijk_datums(value, min, "min")){
            input_fout = true;
          }
        }
      } else {
        //console.log("geen nummer en datum");
      }

      if(stap.sta_type_id == 1 || stap.sta_type_id == 4){ //Open vraag of Gecombineerde vragen
        if(value == ''){
          input_fout = true;
          setAlertOverlay( {title: 'Foutmelding', message: 'Veld is verplicht'} );
        }
      }
      if(value != '' && value != undefined){
        input_filled_counter++;
      }
    });
    if(stap.sta_subtype_id == 5){
      if(stap.sta_min > input_filled_counter){
        setAlertOverlay( {title: 'Foutmelding', message: 'Kies minimaal ' + stap.sta_min + ' antwoord(en)'} );
        return false;
      }
    }


    if(input_fout == true){
      return false;
    }


    /*//Check aan de lengte van de chain welk deel er nu bij moet komen
    const chain_length = param.chain.length;
    const add_chain = chain[chain_length];*/

    //Haal de chain op uit de cache 
    const objectIndex = chain.findIndex((obj => obj.stap_id == stap_id));
    const add_chain = chain[objectIndex];
    const add_vervolg_chain = { ...add_chain, vervolgstapId: vervolgstap }


    //Pas de vervolgstap aan en voeg een deel aan de chain toe vanuit de param_cache
    param.goto = vervolgstap;
    param.moduleNaam = module.mod_naam;
    if (add_chain != null) {
      param.chain.push(add_vervolg_chain);
    }


    if (debug) console.log("volledige param: " + JSON.stringify(param));

    //Sla de param op zodat je dit bij het verversen van het scherm kan ophalen
    commonDispatch({ type: SET_PARAM, value: param });

    //navigation.push( "Main", {module: module.mod_naam });

    // dit voorkomt heel veel rerenders!
    // komt doordat anders elke pagina die je bezocht hebt
    // in het geheugen blijft staan en rerenderd
    setTimeout(() => {
      navigation.reset({
        index: 0,
        routes: [{ name: "Main", params: { module: module.mod_naam } }],
      }); 
    }, 1);
  }

  const prevScreen = () => {
    if (stap.sta_id === 1129 || stap.sta_id === 1122 || stap.sta_id === 1141 || stap.sta_id === 1169) {
      //Haal de chain op uit de cache 
      const objectIndex = chain.findIndex((obj => obj.stap_id == stap_id));
      const add_chain = chain[objectIndex];
      const add_vervolg_chain = { ...add_chain, vervolgstapId: vervolgstap }
      //console.log(add_chain);


      //Pas de vervolgstap aan en voeg een deel aan de chain toe vanuit de param_cache
      param.goto = 1138;
      param.moduleNaam = module.mod_naam;
      if (add_chain != null) {
        param.chain.push(add_vervolg_chain);
      }


      if (debug) console.log("volledige param: " + JSON.stringify(param));

      //Sla de param op zodat je dit bij het verversen van het scherm kan ophalen
      commonDispatch({ type: SET_PARAM, value: param });
      //navigation.push( "Main", {module: module.mod_naam });

      // dit voorkomt heel veel rerenders!
      // komt doordat anders elke pagina die je bezocht hebt
      // in het geheugen blijft staan en rerenderd
      navigation.reset({
        index: 0,
        routes: [{ name: "Main", params: { module: module.mod_naam } }],
      });
    } else {
      if(first_verfijnstap_id !== false && param.goto == first_verfijnstap_id){
        console.log("terug naar advies");
        param.goto = adviesstap_id;
        commonDispatch({ type: SET_PARAM, value: param, moduleNaam: module.mod_naam });
        
        navigation.reset({
          index: 0,
          routes: [{ name: "Main", params: { module: module.mod_naam } }],
        });

      } else {
        var l = param.chain.length;

        //terug naar het startscherm
        if (l == 0) {
          commonDispatch({ type: RESET_PARAM });
        }
        else {
          param.goto = param.chain[l - 1].stap_id;
          param.moduleNaam = module.mod_naam;
          param.chain.pop();
          commonDispatch({ type: SET_PARAM, value: param, moduleNaam: module.mod_naam });
        }

        //Doe er een timeout van 1 miliseconde omheen zodat hij het doet nadat je geredirect bent
        setTimeout(function () {
          //refresh_grafieken();
        }, 1);

        //navigation.push( "Main", {module: module.mod_naam });
        navigation.reset({
          index: 0,
          routes: [{ name: "Main", params: { module: module.mod_naam } }],
        });
      }
    }
  }

  //Tijdelijk
  const vraag = stap.titel;
  const image = stap.achtergrond_landscape;
  const mobileImage = stap.achtergrond_portrait;
  /* Voor image moet het ook uit stap komen ik weet vrijwel zeker dat dit niet de methode is 
  maar ik heb nu het uiteinde van een require in dit variabele aldus : '/background_mobiel.jpeg' bijvoorbeeld */
  const uitleg = get_script(stap.sta_toelichting, true);

  // CookieData
  const [cookiesCaption, setCookiesCaption] = useState('');
  const [cookies, setCookies] = useState('');
  const [videoPlaceholder, setVideoPlaceholder] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');


  function getCookie(cookieDirect) {
    var name = cookieDirect.replace(';', '');
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c === name) {
        return true;
      }
    }
    return '';
  }

  const closeHtmlOverlay = () => {
    setHtmlOverlay(false);
  }

  const clickHandler = (e) => {
    const el = e.target.closest("A");
    if (el && e.currentTarget.contains(el)) {
      // als class gtm aanwezig, dan titel sturen naar Google Analytics
      if ( el.classList.contains('gtm') ){
        sendGaButton( module.mod_naam, el.getAttribute( 'data-title' ) );
      }
      // als het geen javascript functie is, dan afbreken
      if (!el.href.includes('javascript')) return;

      //default acties uitzetten
      e.preventDefault();
      e.returnValue = false;

      //functie uitrafelen
      let href = el.href.replace('javascript:', '');
      const fnc = href.split('(')[0];
      switch (fnc) {
        case 'show_overlay_html':
          //href = href.replace(/'(.)/mg, "$1");//quotes eruit halen
          href = href.replace(/'/g, "%27");//quotes eruit halen
          const tmp = href.split('(').slice(1).join('(');

          //Vind de index van de laatste haak en pak vervolgens daarop de substring
          const lastIndex = tmp.lastIndexOf(')');
          let par = tmp.substr(0, lastIndex)
          par = par.replace(/\\(.)/mg, "$1");//Slashes eruit halen

          //Haal de titel eruit door te splitten op de 1e comma
          const titel = par.split(/,/)[0].replace('%27','').replace(/`/g, '');
          //Haal de tekst eruit door te splitten op de comma, de eerste eraf te slicen en daarna weer aan elkaar plakken
          const tekst = par.split(',').slice(1).join(',');

          //Doe alles bij elkaar en zet h2 om de titel heen
          let total_tekst = '';
          try {
            total_tekst = "<h2>" + titel + "</h2>" + decodeURI(tekst).replace(/`/g, '').replace(/^'/, '').replace(/'*$/, '');//Verwijder de 1ste en laatste hoge komma
          } catch (error){
            if (error instanceof URIError) {
              console.log('URIError:', error.message);
              total_tekst = "<h2>" + titel + "</h2>" + unescape(tekst).replace(/`/g, '').replace(/^'/, '').replace(/'*$/, '');//Verwijder de 1ste en laatste hoge komma
            } else {
              console.log('Error:', error.message);
            }          
          }

          show_overlay_html(total_tekst);
          //refresh_grafieken();
          break;
        case 'check_cookie_iframe':
          //Split alle variabele op (
          href = href.replace(/'(.)/mg, "$1");//quotes eruit halen
          const log = href.split('(').slice(1).join('(');
          const videoVariabele = log.split(',')
          //console.log(videoVariabele);

          //Haal de titel eruit
          setVideoTitle(videoVariabele[0]);
          const titelH2 = "<h2>" + videoVariabele[0] + "</h2>";

          // Haal de youtube link eruit
          //console.log("video:", videoVariabele[1] );
          setYoutubeLink(videoVariabele[1].replace(/`/g, ''));

          // Haal de afbeelding er uit 
          setVideoPlaceholder(videoVariabele[2].replace(/`/g, ''));

          // Haal de cookies caption eruit 
          setCookiesCaption(videoVariabele[3]);

          // Haal cookies eruit
          setCookies(videoVariabele[4]);

          if (!getCookie(videoVariabele[4])) {
            show_overlay_html('[videoPlaceholder][cookiesButton]');
          } else {
            if (mobile) {
              var w = '90vw';
              var h = '80vw';
            }
            else {
              var w = '600';
              var h = '500';
            }
            show_overlay_html('<div style="width: 100%; text-align: center;">' + titelH2 + '<iframe style="width:' + w + ';height:' + h + ';border:1px solid #eeeeee;" width="' + w + '" height="' + h + '" src=' + videoVariabele[1].replace(/`/g, '') + '></iframe></div>');
          }

          break;
        default:
          setAlertOverlay( {title: 'Foutmelding', message: "Onbekende functie: " + fnc} );

      }
      return false;
    }
  }

  function checkInput(value){
    if( value == undefined ) return '';
    if(value == '' || value == '0') return value;

    var txt = value;
    if (typeof txt.startsWith === "function") {
      if( txt.startsWith( '0' ) && txt.charAt(1) != "," && txt.charAt(1) != "." ){
        txt = txt.substring(1);
      }
    }
  return txt;
 }

 const onChange = (value, variabelenaam, id, regex, min, max) => {
  //console.log(min);
  //console.log(max);
  const input = value;
  let cleanInput = checkInput(input);

  if (regex) {
    if (regex.test(cleanInput) || input === "") {
      setContent(cleanInput, variabelenaam, id);
    }
    else{
      setAlertOverlay( {title: 'Foutmelding', message: "Verkeerde invoer, vul alleen getallen en komma's in."} );
      return;
    }
  } else {
    setContent(cleanInput, variabelenaam, id);
  }

  // controleeer eventueel min en max, maak alles float door de commas te vervangen voor punten
  cleanInput = cleanInput.replace(',', '.');
  if(isNumber(cleanInput)){
    //console.log("het is een nummer");
    //console.log(Number.parseFloat(min));
    //console.log(Number.parseFloat(cleanInput));
    if(max){
      max = max.replace(',', '.');
      if(Number.parseFloat(cleanInput) > Number.parseFloat(max)){
        setAlertOverlay( {title: 'Foutmelding', message: "Invoerwaarde mag niet groter zijn dan " + max} );
      }
    }
    if(min){
      min = min.replace(',', '.');
      if(Number.parseFloat(cleanInput) < Number.parseFloat(min)){
        setAlertOverlay( {title: 'Foutmelding', message: "Invoerwaarde mag niet kleiner zijn dan " + min} );
      }
    }
  }     

};

 
const set_placeholder = ( data ) => {

  if ( data.input_type == "number"  ) {
    let placeholder = data.placeholder || "";
    if ( data.min ) {
      if ( placeholder ) placeholder += ", ";
      placeholder += "minimum = " + data.min;
    }
    if ( data.max ) {
      if ( placeholder ) placeholder += ", ";
      placeholder += "maximum = " + data.max;
    }
    //if ( placeholder ) placeholder += ", ";
    //placeholder += ", " + data.dec + " decimalen";
    return placeholder;
  }

  return data.placeholder;

}

  function setCookie(cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cookies + "=" + cvalue + ";" + expires + ";path=/";
    const titelH2 = '<h2 style="text-align: center">' + videoTitle + '</h2>';
    if (mobile) {
      var w = '90vw';
      var h = '80vw';
    }
    else {
      var w = '600';
      var h = '500';
    }
    show_overlay_html('<div style="width: 100%; text-align: center;">' + titelH2 + '<iframe style="width:' + w + ';height:' + h + ';border:1px solid #eeeeee;" width="' + w + '" height="' + h + '" src="' + youtubeLink + '"></iframe></div>');
  }

  const show_overlay_html = (tekst) => {
    setHtmlOverlay(true);
    setHtml(tekst);
  }

  useEffect(() => {
    //console.log(stap.pagina_tag);
    sendGa(module.mod_naam, stap.pagina_tag);

  }, []);


  const handleEscKey = useCallback((event) => {
    if (event.key === 'Escape') {
      setHtmlOverlay(false);
    }
  }, []);



  useEffect(() => {
    const beforeUnloadHandler = (event) => {
      // Recommended
      event.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = '';
    };


    document.addEventListener('keyup', handleEscKey, false);
    if(param.goto != 'first'){
      window.addEventListener("beforeunload", beforeUnloadHandler);
    }
    return () => {
      document.removeEventListener('keyup', handleEscKey, false);
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [handleEscKey]);



  let fontAfwijking = false;
  if (global.settings.font_regular !== 'ProximaNova-Regular.otf') {
    fontAfwijking = true;
  }

  return useMemo(() => {
    //Als het radiobuttons of checkboxes zijn dan is 1 aangeklikt veld al genoeg om de knop te activeren
    if (vraagtype == "mc_enkel" || vraagtype == "mc_multi") {
      inputAantal = stap.sta_min;
    }

    let role = '';
    switch (vraagtype) {
      case 'mc_enkel':
        role = 'radiogroup';
        break;
      case 'mc_multi':
        role = 'group';
        break;
      default:
        role = '';
    }
    if (debug) console.log('render vraagContainer');
    return (
      <View>
        { global.settings.show_progress_bar > 0 ?
          <View style={{ zIndex: 10, height: 15, width: '100%', overflow: 'hidden', borderTopColor: '#999999', borderTopWidth: 1, backgroundColor: '#F7F7F7' }}>
            <ProgressBarAnimated
              width={Dimensions.get('window').width}
              height={15}
              overflow='hidden'
              backgroundColor={Colors[2]}
              borderWidth={0}
              colorGradientArray={global.settings.progressbar_colors}
              value={progress}
            />
          </View>
        : null }
        
      <View style={mobile ? null : styles.fullScreen}>
          <Modal 
            animationType={global.settings.overlay_animation || "slide"}
            visible={htmlOverlay}
            transparent={true}
          >
            <View style={[styles.htmlOverlay, { left: mobile ? 5 : 40, top: inFrame() ?  10 : "5%" }]}>
              {inFrame() ?
              <view>
                  <ScrollView accessible={true} contentContainerStyle={{ maxHeight: Dimensions.get('window').height - 100}}>
                    <Button arrowIcon={false} color={Colors[7]} bottom={0} square={false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={'Sluiten'} onPress={() => closeHtmlOverlay()} />
                    <div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px', zIndex: 99999 }} dangerouslySetInnerHTML={{ __html: textReplace(html, ['','']) }} />
                    {html.includes('[videoPlaceholder]') ? <Image style={{ height: 500, width: 600, alignSelf: 'center' }} source={{ uri: videoPlaceholder }} /> : null}
                    {html.includes('[cookiesButton]') ? <View style={{ position: 'absolute', bottom: 100, alignSelf: 'center' }}><Button arrowIcon={false} color={Colors[3]} bottom={0} square={false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={cookiesCaption} onPress={() => setCookie(1, 30)} /></View> : null}
                    {html.includes('[activityIndicator]') ? <View style={{ height: 500, width: 600, alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}><ActivityIndicator size={'large'} color={Colors[7]} /></View> : null}
                    <Button arrowIcon={false} color={Colors[7]} bottom={0} square={false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={'Sluiten'} onPress={() => closeHtmlOverlay()} />
                </ScrollView> 
              </view>: 

              <View accessible={true} >
                  <Button arrowIcon={false} color={Colors[7]} bottom={0} square={false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={'Sluiten'} onPress={() => closeHtmlOverlay()} />
                  <div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px', zIndex: 99999 }} dangerouslySetInnerHTML={{ __html: textReplace(html, ['','']) }} />
                  {html.includes('[videoPlaceholder]') ? <Image style={{ height: 500, width: 600, alignSelf: 'center' }} source={{ uri: videoPlaceholder }} /> : null}
                  {html.includes('[cookiesButton]') ? <View style={{ position: 'absolute', bottom: 100, alignSelf: 'center' }}><Button arrowIcon={false} color={Colors[3]} bottom={0} square={false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={cookiesCaption} onPress={() => setCookie(1, 30)} /></View> : null}
                  {html.includes('[activityIndicator]') ? <View style={{ height: 500, width: 600, alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}><ActivityIndicator size={'large'} color={Colors[7]} /></View> : null}
                  <Button arrowIcon={false} color={Colors[7]} bottom={0} square={false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={'Sluiten'} onPress={() => closeHtmlOverlay()} />
              </View>}
            </View>
          </Modal>
          {mobile ?
            <View style={{ backgroundColor: Colors[12] }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingHorizontal: 30, paddingTop: 10 }}>
                {vraagNummer && module.mod_teller_actief == 1 ? <View accessible={true} style={{ marginBottom: 10 }}>
                  <P accessible={true} fontSize={tablet ? 14 : 12}>{'Vraag ' + vraagNummer + '/' + vraagMax}</P>
                </View> : null}
              </View>
              <View style={{ paddingHorizontal: 30, top: -10, paddingBottom: 10, marginTop: 10 }}>
                <H4 accessible={true} realHeader={true} level="1" fontWeight='700' accessible={true} maxWidth={350} fontSize={18} color={Colors[17] !== '' ? Colors[17] : Colors[2]}>{vraag}</H4>
                {vraagtype == 'open_enkel' || uitleg == '' ? null : <View accessible={true} style={{ marginTop: 10 }}>
                  <div onClick={clickHandler} style={{ fontFamily: "Regular", fontSize: tablet ? 16 : 18, lineHeight: '120%', color: Colors[9] }} dangerouslySetInnerHTML={{ __html: uitleg }} />
                </View>}
              </View>
              {mobileImage ? <Image aria-hidden={true} style={{ width: 300, height: stap.sta_id == 119 || stap.sta_id == 1075 ? 75 : 150, alignSelf: 'center' }} source={{ uri: mobileImage }} /> : null}
            </View>
            :
            <LeftContainer
              title={vraag}
              clickHandler={clickHandler}
              vraagNummer={vraagNummer}
              vraagMax={vraagMax}
              image={image}
              module={module}
              tablet={tablet}
              mobile={mobile}
              subtitle={vraagtype == 'open_enkel' ? null : uitleg}
              navigation={props.navigation}
            />
          }
          <RightContainer
            role={role}
            config={config}
            clickHandler={clickHandler}
            stapData={stap}
            inputsContent={
              stap.antwoorden.map((data, index) => {
                let longtekst = null;
                if (data.tekst) {
                  if (data.tekst.length > 35) {
                    longtekst = true;
                  }
                }
                //Voeg aan de hand van het type verschillende components toe
                if (data.input_type == "dropdown") {
                  const dropdown = data.dropdown;
                  const dropdown_array = dropdown.split("\r\n");

                  //Vul een array met alle dropdown values en voeg de placeholder aan het begin daar aan toe
                  const dropdown_values = dropdown_array.map((data) => {
                    return { label: data.split(":")[1], value: data.split(":")[0] };
                  });
                  //console.log(dropdown_values);

                  let full_dropdown_array = dropdown_values;
                  if (data.placeholder != "" && data.placeholder != null) {
                    full_dropdown_array = [{ label: data.placeholder, value: "" }, ...dropdown_values];
                  }
                  let first_dropdown_option = full_dropdown_array[0];//Placeholder
                  const new_label = stripFirstColon(first_dropdown_option.label);
                  first_dropdown_option.label = new_label;

                  //console.log(full_dropdown_array);
                  //const first_dropdown_option = full_dropdown_array.shift();
                  return (
                    {
                      icon: icons[index],
                      titel: <View style={{ marginLeft: 5, marginTop: mobile ? 5 : 0}}><H4 maxWidth={tablet ? '100%' : '100%'} accessible={true} color={Colors[9]}>{global.settings.text_to_upper_case == 0 ? data.titel : data.title.toUpperCase()}</H4></View>,
                      input: <DropDown
                        tablet={tablet}
                        mobile={mobile}
                        maxWidth={maxVraagBreedte}
                        onChange={(value) => setContent(value, data.variabelenaam, data.id)}
                        value={newArrayAnt[data.id] ?? ''}
                        items={full_dropdown_array}
                        style={[styles.dropDownStyle, {borderRadius: global.settings.border_radius }, decideCSS(data.variabelenaam, statusData)]}
                        enabled={decideEnable(data.variabelenaam, statusData)}
                      />,
                      show: decideShow(data.variabelenaam, statusData),
                      name: data.variabelenaam
                    }
                  )
                }
                else if (data.input_type == "geo_autocomplete") {
                  return (
                    {
                      icon: icons[index],
                      titel: <H4 maxWidth={tablet ? '50%' : '80%'} accessible={true} color={Colors[9]}>{global.settings.text_to_upper_case == 0 ? data.titel : data.title.toUpperCase()}</H4>,
                      input: <GooglePlacesInput
                        tablet={tablet}
                        mobile={mobile}
                        maxWidth={'100%'}
                        placeholder={data.placeholder}
                        onPress={(geo_data, geo_details = null) => {
                          //console.log(geo_data);
                          setContent(geo_data.description, data.variabelenaam, data.id);
                        }}
                        setText={newArrayAnt[data.id] ?? ''}
                        style={[styles.inputHome, { borderRadius: global.settings.border_radius, width: maxVraagBreedte }, decideCSS(data.variabelenaam, statusData)]}
                        enable={decideEnable(data.variabelenaam, statusData)}
                        tussenvraag={true}
                      />,
                      show: decideShow(data.variabelenaam, statusData),
                      name: data.variabelenaam
                    }
                  )                  
                } else if (data.input_type == "autocomplete") {
                  return (
                    {
                      icon: icons[index],
                      titel: <H4 maxWidth={tablet ? '100%' : maxVraagBreedte} accessible={true} color={Colors[9]}>{data.titel}</H4>,
                      input: <AutocompleteInput
                        antwoordId={data.id}
                        maxWidth={ maxVraagBreedte }
                        onSubmit={() => nextScreen()}
                        placeholder={data.placeholder}
                        valueSetter={(value) => setContent(value, data.variabelenaam, data.id)}
                        style={decideCSS(data.variabelenaam, statusData)}
                        //autoFocus={index === 0 ? true : false}
                        tablet={tablet}
                      />
                    }
                  )
                }
                else if (data.input_type == "date") {
                  //console.log( 'datumveld', newArrayAnt[data.id] );
                  return (
                    {
                      icon: icons[index],
                      titel: index === 0 || showInput && index === 1 ? <H4 maxWidth={tablet ? '100%' : '100%'} accessible={true} color={Colors[9]}>{global.settings.text_to_upper_case == 0 ? data.titel : data.titel.toUpperCase()}</H4> : null,
                      input: <DateInput
                        tablet={tablet}
                        mobile={mobile}
                        maxWidth={ maxVraagBreedte }
                        stap={stap.sta_id}
                        //cacheValue={param_cache.chain.length > 0 ? param_cache.chain[0].antwoord.length === 2 ? {valueOne: param_cache.chain[0].antwoord[0], valueTwo: param_cache.chain[0].antwoord[1]} : null : null}
                        cacheValue = { newArrayAnt[data.id] }
                        showInputVar={showInput}
                        showInput={() => setShowInput(true)}
                        hideInput={() => setShowInput(false)}
                        index={index}
                        style={[styles.inputHome, { borderRadius: 0, width: '100%' }, decideCSS(data.variabelenaam, statusData)]}
                        //autoFocus={mobile ? false : index === 0 ? true : false}
                        placeholder={'Datum'}
                        onChangeText={(value) => setContent(value, data.variabelenaam, data.id)}
                      />
                    }
                  )
                }
                else if (data.input_type == "number" || data.input_type == "text" || data.input_type == "memo") {
                  count++
                  if (stap.sta_id == 911 && count === 1 || stap.sta_id == 1068 && count >= 1 && count <= 4 || stap.sta_id == 1076 && count >= 21 && count <= 31) {
                    setContent(newArrayAnt[data.id] ?? '', data.variabelenaam, data.id);
                  }
                  if (stap.vraagtype == 'open_multi' || stap.vraagtype == 'open_enkel') {
                    stap.antwoorden.map((vraag, index) => {
                      if (vraag.subtype == 7) {
                        if (onlyOnce) {
                          setContent(newArrayAnt[data.id] ?? '', data.variabelenaam, data.id);
                          onlyOnce = false;
                        }
                        inputDataFilled++;
                      }
                    });
                  }
                  
                  return (
                    {
                      icon: icons[index],
                      titel:  <View accessible={true} >
                      <div 
                        maxwidth={tablet ? '80%' : mobile ? '100%' : '100%'} 
                        accessible='true' 
                        onClick={clickHandler} 
                        style={{ fontFamily: "Regular", fontSize: tablet ? 16 : 18, lineHeight: '120%', color: Colors[9] }} 
                        dangerouslySetInnerHTML={{ __html: 
                          vraagtype == 'open_enkel' ? 
                            uitleg 
                          : global.settings.text_to_upper_case == 0 ? 
                              data.titel 
                            : data.title.toUpperCase() 
                          }} 
                        />
                        </View>,
                      input: <TextInputDefault
                        tablet={tablet}
                        //autoFocus={mobile ? false : index === 0 ? true : false}
                        onSubmit={() => nextScreen()}
                        placeholder={ set_placeholder( data ) }
                        style={[styles.inputHome, { borderRadius: global.settings.border_radius, width: maxVraagBreedte }, decideCSS(data.variabelenaam, statusData)]}
                        input={ (typeof newArrayAnt[data.id].replace === "function")  ?
                          newArrayAnt[data.id].replace('.', ',') : newArrayAnt[data.id] || '' 
                        }
                        onChangeText={(value) => onChange(value, data.variabelenaam, data.id, (data.input_type == "number" ? /^[0-9-,]+$/ : null))}//Aan de hand van het type een regex meegeven
                        onBlur={(value) => {
                          if(data.dec !== '') {
                            value = value.replace(",",".");
                            value = Math.round(value * 10 ** data.dec ) / 10 ** data.dec;
                            value = value.toString();
                            value = value.replace(".",",");
                          }
                          onChange(value, data.variabelenaam, data.id, (data.input_type == "number" ? /^[0-9-,]+$/ : null), data.min, data.max);
                          setHtmlOverlay(false);
                        }}
                        placeholderTextColor={Colors[9]}
                        returnKeyType="next"
                        editable={decideEnable(data.variabelenaam, statusData)}
                        pattern={(data.input_type == "number" ? /^[0-9-,]+$/ : '')}
                      />,
                      show: decideShow(data.variabelenaam, statusData),
                      name: data.variabelenaam
                    }
                  )
                }
                else if (data.input_type == "radio") {
                  return (
                    {
                      icon: icons[index],
                      input_type: data.input_type,
                      input: <CustomCheckCard
                        onPress={() => { setContent(1, "enkel", data.id, data.vervolgstap) }}
                        value={radioButton[data.id]}
                        data={data}
                        longtekst={longtekst}
                        maxWidth={maxVraagBreedte}
                        icons={stap.antwoorden}
                        index={index}
                        tablet={tablet}
                        mobile={mobile}
                        type={'radio'}
                      />
                    }
                  )
                }
                else if (data.input_type == "check") {
                  return (
                    {
                      icon: icons[index],
                      input_type: data.input_type,
                      input: <CustomCheckCard
                        onPress={() => { setContent(newArrayAnt[data.id], data.volgnummer, data.id, null, data.exclusief) }}
                        value={newArrayAnt[data.id]}
                        data={data}
                        maxWidth={maxVraagBreedte}
                        longtekst={longtekst}
                        icons={stap.antwoorden}
                        index={index}
                        tablet={tablet}
                        mobile={mobile}
                        type={'checkbox'}
                      />
                    }
                  )
                }
                else {
                  return (
                    {
                      content: <H4 accessible={true} alignSelf={'left'}>Type niet gevonden</H4>,
                    }
                  )
                }
              })
            }
            mobile={mobile}
            tablet={tablet}
            buttonContent={'Toon klimaatimpact en advies'}
            inputDataFilled={inputDataFilled}
            inputAantal={inputAantal}
            onPressPrev={() => prevScreen()}
            onPressNext={() => nextScreen()}
            onHomePress={() => home()}
            statusData={statusData}
          />
          <Message
            visible={alertOverlay}
            content={alertOverlay}
            onPress={ () => { setAlertOverlay(false); }}
          />
        </View>
      </View>
    )

  }, [param_cache, htmlOverlay, setCookie, fontAfwijking, newArrayAnt])
}



// Style
const styles = StyleSheet.create({
  fullScreen: {
    flexDirection: 'row',
    width: '100%',
  },
  inputHome: {
    marginRight: 5,
    marginTop: 5,
    height: 40,
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: Colors[11],
    alignSelf: 'left',
    color: Colors[4],
    fontSize: 16,
  },
  dropDownStyle: {
    color: Colors[9],
    marginBottom: 10,
    height: 40,
    paddingBottom: 5,
    paddingTop: 4,
    paddingHorizontal: 20,
    borderWidth: 0,
    fontSize: 16,
    fontFamily: 'Regular',
  },
  htmlOverlay: {
    backgroundColor: 'rgba(255,255,255, 1)',
    flex: 1,
    position: 'absolute',
    zIndex: 99999,
    maxWidth: 1140,
    maxHeight: 580,
    overflowY: 'scroll',
    width: '100%',
    borderRadius: 25,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    paddingHorizontal: 20,
    paddingVertical: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    right: 0,
  },

});